
// _base.scss imports _custom.scss and other partials.
@import "base";

/*
 * @file
 * Print Styles
 * Styles in this file are for the browser print function
 * AND for PDF styling (classes mit print- prefix)
*/

/* Bufixes + Reset */
// dont know the reasion - but labeled fields get floated, so we try to override this behavoir
*{
  float:none !important;
  line-height:$line_height !important;
  text-align:left !important;
}
.field,
.field-label{
  float:none !important;
  text-align:left !important;
  line-height:$line_height !important;
  clear:both !important;
  display:block !important;
}
.field{
  margin-bottom:9pt;
}
.field-label-inline{
  .field-label,
  .field{
    display:inline !important;
  }
}


/*
 * Body
 */
body {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1.4;
  word-spacing: 1.1pt;
  letter-spacing: 0.2pt;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  background: none;
  font-size: 10pt;
}


/*
 * Links
 */
a {
  background: transparent;
  font-weight: normal;
  text-decoration: underline;
  color: $text_color;
}

/*
 * Strip background from likely candidates
 */
div[id*="wrapper"],
[class*="title"],
[class*="inner"],
header,
footer,
#branding,
#container,
#columns,
.columns-inner,
#content-column,
.content-inner,
.region,
.region-header,
.region-content,
.article,
.article-title,
.article-content,
.comment,
.comment-title,
.comment-content,
.block,
.block-inner,
.block-title,
.block-content,
table,
table *{
  background: none !important;
}


/*
 * Hide various bits of the page
 */
.sidebar,
#breadcrumb,
.nav,
.field-type-taxonomy-term-reference,
ul.links,
.feed-icon,
.poll .bar,
.poll .foreground,
.comment-form,
#comment-form,
.book-navigation,
.tabs,
.action-links,
.pager,
.messages,
.help,
.page-panel-sidebar-first,
.region-navigation-bar,
.drowl-headroom-wrapper,
.region-above-footer,
#webks-offcanvas-layer,
.breadcrumb,
#footer,
.region-header,
.pane-ip-faq-tagcloud,
.view-header-slideshow,
.pane-ip-stellenangebote-block,
.field-type-file,
#sliding-popup,
#admin-menu,
#webks-dev-bar,
body > *:not(#page):not(#page-wrapper):not(#header){
  display: none !important;
}


/*
 * Ensure the display the main content region
 */
#content {
  display: block !important;
}


/*
 * Layout
 */
#header {
  margin-bottom: 20px;
}


.content-inner {
  margin: 0 !important;
  padding: 0 !important;
}


/*
 * HTML elements
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text_color;
  font-weight:$font_weight_normal;
  margin: 1em 0 1.25em 0;
  padding: 0;
  line-height: 1;
  clear:both;
}


h1 {
  font-size: 14pt;
}


h2 {
  font-size: 13pt;
}


h3 {
  font-size: 12pt;
}


h4,
h5,
h6 {
  font-size: 11pt;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
#site-name a,
#site-slogan {
  text-decoration: none !important;
  color: $text_color;
}


hgroup a {
  &:link,
  &:visited {
    text-decoration: none;
  }
}


#page-title {
  margin-top: 20px;
}


pre,
code,
tt,
samp,
kbd,
var {
  font-size: 10pt;
  font-family: Consolas, "Lucida Console", Menlo, Monaco, "DejaVu Sans Mono", monospace, sans-serif;
}


blockquote {
  margin: 20px;
  padding: 10px;
  font-size: 10pt;
  font-style: italic;
  background: $text_color;
}


hr {
  background-color: $grey_dark;
}


/*
 * Header, Logo, Site name
 */
#header {
  padding-bottom: 20px;
}


/* Print Logo (different from screen Logo) */

/*
 * Logo
 */
.page-logo {
  // hide screen logo
  display:none;
}
.page-print-logo{
  // hide print logo on screen devices
  display:block;
  text-align:right !important;
  img {
    padding: 0 10px 0 0;
    margin: 0 !important;
    float: none !important;
    max-width:25%;
    height:auto;
  }
}

#logo{
  text-align:right;
  img {
    padding: 0 10px 0 0;
    margin: 0 !important;
    float: none !important;
    max-width:25%;
    height:auto;
  }
} 


/*
 * Images
 */
img {
/*  float: left;
  margin: 4px 20px 10px 0;
  page-break-inside: avoid;*/
}


a img {
  border: none;
 }


/*
 * Tables
 */
table {
  margin: 1px;
  text-align: left;
}


th {
  border-bottom: 1px solid $grey;
  font-weight: 700;
}


td {
  border-bottom: 1px solid $grey;
}


th,
td {
  padding: 4px 10px 4px 0;
}


tfoot {
  font-style: italic;
}


caption {
  margin-bottom: 10px;
  text-align: left;
}


thead {
  display: table-header-group;
}


tr {
  page-break-inside: avoid;
}


/*
 * Forms
 */
form {
  margin-bottom: 10px; }


/* Views */
.views-row{
  clear:both;
}

/*
 * Poll module
 */
.poll {
  .text,
  .percent,
  .total {
    text-align: left;
  }
  form {
    margin-bottom: 0;
  }
}


/*
 * Articles
 */
.node,
.comment {
  margin-bottom: 20px;
}

.submitted {
  color: rgb(102, 102, 102);
  text-decoration: none;
  font-size: 9pt;
  font-weight: 400;
  margin: 0;
  padding: 0;

  a {
    color: rgb(102, 102, 102);
    text-decoration: none;
    font-size: 9pt;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
}

/* Bugfix Getdirections Print */
.getdirections table,
.getdirections tbody,
.getdirections tr,
td.getdirections-list{
  display:block;
  width:100%;
}
.td.getdirections-map,
.getdirections-map > *{
  width:500pt !important;
  height:300pt !important;
}